import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Monday, May 16th`}</strong></p>
    <p>{`500M Row`}</p>
    <p>{`50 Pushups, 50 Situps, 50 Squats`}</p>
    <p>{`400M Row`}</p>
    <p>{`40 Pushups, 40 Situps, 40 Squats`}</p>
    <p>{`300M Row`}</p>
    <p>{`30 Pushups, 30 Situps, 30 Squats`}</p>
    <p>{`200M Row`}</p>
    <p>{`20 Pushups, 20 Situps, 20 Squats`}</p>
    <p>{`100M Row`}</p>
    <p>{`10 Pushups, 10 Situps, 10 Squats`}</p>
    <p>{`For Time. (Games Standard Pushups)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      